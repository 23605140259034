<script>
	import { setGdprVisibility } from '$lib/stores/app/actions';
	import { PAGES } from '$lib/utils/constants';

	export let config = {
		closeButton: '/assets-mkt/template/gdpr-banner/close-icon-v1.png',
		paragraphOne:
			'We use cookies to deliver the best possible experience on our website and to analyse usage.',
		paragraphTwo: `Our <a class="text-[#0A4792] inline underline" href="${PAGES.EXTERNAL.AOFL_ABCMOUSE_COOKIE_POLICY}" target="_blank" rel="noopener noreferrer">Cookie Policy</a> provides further information on our cookie uses and how to amend your cookie settings.`,
		paragraphThree:
			'If you continue to use our website we will assume you are ok with our use of cookies.'
	};

	function hideBanner() {
		setGdprVisibility(false, true);
	}
</script>

<div
	class="z-100 fixed bottom-0 left-0 z-[100] max-h-[45vh] w-full overflow-y-auto bg-[#EBEAEB] px-6 pb-3 pt-16 shadow-[0_0_20px_0_rgba(128,128,128,1)] sm:px-[13rem]"
>
	<div
		class="absolute right-[1rem] top-[1.5rem] z-102 w-[3rem] cursor-pointer sm:right-[2.5rem] sm:w-[4.5rem]"
		role="button"
		tabindex="0"
		on:click={hideBanner}
		on:keydown={hideBanner}
	>
		<img id="close-button" src={config.closeButton} alt="Close banner" />
	</div>
	<div>
		<p class="mb-4 text-[1.2rem] font-light sm:text-[1.8rem]">{config.paragraphOne}</p>
		<p class="mb-4 text-[1.2rem] font-light sm:text-[1.8rem]">{@html config.paragraphTwo}</p>
		<p class="mb-4 text-[1.2rem] font-light sm:text-[1.8rem]">{config.paragraphThree}</p>
	</div>
</div>
